import {Message} from "../Message/Message";
import "./MessagesBox.css";
import {useCallback} from "react";

export function MessageBox({messages, userProps = {}, showActionButtons=false,}) {


    const getEditButtons = useCallback(() => {
        if (userProps.userProps && ['admin', 'operator', 'teacher'].includes(userProps.userProps.admin) && showActionButtons) {
            return (
                <div className={"p-1 me-1"}>
                    <button className={"add-icon fa fa-add fa-2x"}>ערוך</button>
                </div>
            )
        }
    }, [userProps, showActionButtons]);

    return (
        <div className={"message-box"}>
            {getEditButtons()}
            {messages.map((message, index) => (
                <Message key={index} message={message.message} title={message.title} timestamp={message.timestamp} type={message.type}/>
            ))}
        </div>
    )
}