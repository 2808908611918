import "./StudentCardInfo.css";

export const StudentCardInfo = ({ student }) => {
    return (
        <div className={'student-card-info'}>
            <div className={'student-name'}>
                {student.first_name} {student.last_name}
            </div>
            <div>
                היה באיחור <span className={'fw-bold'}>{student.attended_late_counter}</span> פעמים החודש
            </div>
        </div>
    );
}