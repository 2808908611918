
export const getMessageThemes = (theme) => {
    return themes[theme] || themes.default;
}

const themes = {
    default: {
        primaryBackGroundColor: "#f8f9fa",
        secondaryBackGroundColor: "#f8f9fa",
        primaryTextColor: "#1c1c1c",
        secondaryTextColor: "#121212",
        faIcon: "fa fa-info-circle",
    },
    success: {
        primaryBackGroundColor: "#d4edda",
        secondaryBackGroundColor: "#c3e6cb",
        primaryTextColor: "#155724",
        secondaryTextColor: "#155724",
        faIcon: "fa fa-check-circle",
    },

    error: {
        primaryBackGroundColor: "#f8d7da",
        secondaryBackGroundColor: "#f5c6cb",
        primaryTextColor: "#721c24",
        secondaryTextColor: "#721c24",
        faIcon: "fa fa-exclamation-circle",
    },

    warning: {
        primaryBackGroundColor: "#fff3cd",
        secondaryBackGroundColor: "#ffeeba",
        primaryTextColor: "#856404",
        secondaryTextColor: "#856404",
        faIcon: "fa fa-exclamation-triangle",
    },

    info: {
        primaryBackGroundColor: "#cce5ff",
        secondaryBackGroundColor: "#b8daff",
        primaryTextColor: "#004085",
        secondaryTextColor: "#004085",
        faIcon: "fa fa-info-circle",
    },
}