import "./StudentCard.css";
import {ProfilePicture} from "../ProfilePicture/ProfilePicture";
import {StudentCardInfo} from "../StudentCardInfo/StudentCardInfo";

const StudentCard = ({ student }) => {
    return (
        <div className={'student-card-container'}>
            <ProfilePicture image={student.profile_picture}></ProfilePicture>
            <hr className={'student-card-hr'} />
            <StudentCardInfo student={student}></StudentCardInfo>
        </div>
    );
}

export default StudentCard;