import "./personalFile.css";
import {useEffect, useState} from "react";
import {getPersonalMessages} from "../Db/DataBase";
import {MessageBox} from "../Components/Messages/MessagesBox/MessageBox";
import StudentCard from "./components/StudentCard/StudentCard";
import {StudentsBox} from "./components/StudentsBox/StudentsBox";

export function PersonalFile(userProps) {
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        getPersonalMessages(userProps.userProps.email, userProps.userProps.password, userProps.userProps.uuid).then((messages) => {

            setMessages(messages.map((message) => {
                return {
                    message: message.message,
                    title: message.creator_name,
                    timestamp: message.creation_time,
                    type: message.type
                }
            }));
        })
    }, [userProps]);

    const student = {
        profile_picture: "https://www.w3schools.com/howto/img_avatar.png",
        first_name: "דוד",
        last_name: "מיסטריאל",
        attended_late_counter: 3,
    }

    return (
        <div className="personal-file margin-top-responsive">
            <MessageBox showActionButtons={true} messages={messages} userProps={userProps}/>
            <div className="personal-file">
                {/*<MessageBox messages={messages} userProps={userProps}/>*/}
                <div className={'students-container'}>
                    <StudentsBox>
                        <StudentCard student={student}></StudentCard>
                    </StudentsBox>
                </div>
            </div>
        </div>
    );
}

